.wrapper {
    width: 95%;
    height: 100vh;
    overflow-y: scroll !important;
    overflow-x: hidden;
    padding: 0 20px 20px 0;
}

.wrapper::-webkit-scrollbar {
    width: 10px;
  }
  .wrapper::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 20px; 
  }
  .wrapper::-webkit-scrollbar-thumb {
    background-color: #8d8fe7; 
    border-radius: 20px; 
    border: 3px solid rgb(241, 241, 241); 
  }

.title {
    display: flex;
    margin-top: 29px;
}

.title span {
    font-size: 24px;
    font-family: "Inter";
    font-weight: 600;
}

.info {
    height: 300px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 1%;
}
.mobileInfo {
    display: none;
}

.phone {
    font-family: "mont";
    font-size: 23px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 5px;

}

.mobBtnContainer {
    padding: 0 10px;
}
.mobBtn {
    border-radius: 8px;
    height: 36px;
    border: 1px solid rgb(77, 113, 129);
    background: 0;
    color:rgb(68, 68, 68);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}


.name {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 3fr;
    padding: 0 20px;
    gap: 10px;
}

.balanceContainer {
    display: flex;
    gap: 5%;
    justify-content: center;
    padding: 0 3%;
}

.action {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 6px;
}



.action button {
    background: #FFFFFF;
    border: 1px solid #5D5FEF;
    border-radius: 8px;
    height: 36px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;

}
.action button:hover {
    background: #f8f8f8;
}



.photo {
    background: #5D5FEF;
    border-radius: 16px;
    /* padding: 0 20px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5%;
    /* justify-content: space-between; */
}

.item {
    width: 20%;
    height: 85%;
    flex-grow: 1;
    background: #F8F8F8;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    gap: 5px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}

/* .item span:nth-child(2) {
    font-size: 40px;
} */

.notification {
    height: 325px;
    margin-top: 19px;
    display: flex;
    gap: 6px;
}

.itemNot {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(68, 68, 68, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    
    
    
}

.add {
    background: rgba(68, 68, 68, 0.8);
    border-radius: 8px;
    height: 50px;
    padding: 5px;
}

.addContent {
    padding-right: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}
.addContent img:hover{
    border: 1px solid #fff;
    cursor: pointer;
    transition: 0.5s;
}

.reqContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    height: 80%;
    flex-grow: 1;
    gap: 14px;
}

.reqContent p {
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    line-height: 18px;
    font-weight: 300;
    color: rgba(68, 68, 68, 0.8);
    padding: 10px 15px;
}

.photos {
    margin-top: 28px;
    margin-bottom: 28px;
    width: 100%;
    display: flex;
    gap: 20px;
}

.photosPhoto {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.photosItem {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}
.photoLabel {
    position: absolute;
    height: 199px;
    width: 275px;
    border: 2px solid #aaaaaa;
    background: #fff;
    z-index: 4;
    border-radius: 8px;
    top: 70px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.photoLabelContainer {
    width: 100%;
    height: 139px;
    border: 2px dotted #000;
    border-radius: 8px;
    display: flex;
    align-items: center;

}
.photoLabelBtn {
    height: min-content;
    background: 0;
    font-size: 18px;
    color: black;
    font-weight: 600;
}
.photoLabelBtn:hover {
    color: #5d5ffe;
    cursor: pointer;
}

.photosComment {
    width: 40%;
}

.head {
    background: rgba(68, 68, 68, 0.8);
    border-radius: 8px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: #FFFFFF;
    gap: 15px;
}

.photosContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.photosContainer img {
    width: 190px;
    height: 190px;
    border-radius: 8px;
    object-fit: cover;
}
.ava {
    position: relative;
  
}
.ava:hover {
    cursor: pointer;
}
.ava:hover img {
    
    opacity: 0;
}
.ava:hover .inputFile {
   opacity: 1;
}
.inputFile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
   background: #fff !important;
   opacity: 0;
}
.inputFile:hover {
    cursor: pointer;
}
.inputFile svg {
    transform: scale(2.2);

}
.ava img {
    width: 105px;
    height: 105px;
    border-radius: 50%;
}

.nameSurname {
    display: flex;
    flex-direction: column;
    font-size: 25px;
    padding: 0 20px;
}

.n {
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.np {
    color: #FFFFFF;
    display: flex;
    gap: 10px;

}

.visitContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 100%;
    justify-content: space-between;
    padding: 10px 5px;
}

.currentVisit {
    background: #F8F8F8;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 100%;
    height: 100%;

}

.queue {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}




.addCommentArea{
    background-color: #F8F8F8;
    width: 100%;
    height: 140%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid rgb(181, 181, 181);
    border-radius: 20px;
}

.addComment{
    background-color: #598866;
    color: #F8F8F8;
    width: 123px;
    height: 36px;
    border: transparent;
    border-radius: 10px;
    font-weight: bold;
}

.texArea{
    border-radius: 5px;
    border: 1px solid rgb(181, 181, 181);
    resize: none;
    outline: none;
    padding: 5px;
    overflow: auto;
    width: 70%;
}
.actionClient{
    display: none;
}
@media screen and (width < 768px) {
    .item span:nth-child(2) {
        font-size: 14px;
    }
    .item span {
        font-size: clamp(10px, 5px, 1px);;
    }
    .item {
        max-height: 50px;
        width: 100%;
    }
    .balanceContainer{
        flex-direction: column;
    }
    .actionClient{
        width: 100%;
        height: 20px;
        background: #f0eeff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }
    .actionClient button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        width: 100%;
    }
    .action {
        display: none;
    }
    .phone {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        order: 0;
        justify-content: center;
        align-items: center;
    }
    .title {
        display: none;
    }
    .title span{
        font-size: 15px;
    }
    .info {
        display: none;
    }
    .mobileInfo {
        display: flex;
        flex-direction: column;
        
       
        width: 100%;
        position: relative;
    }
    .inputFile {
        position: absolute;
        height: 100%;
        width: 100%;
        
    }
    .name{
        order: 1;
    }
    .action2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
        gap: 6px;
    }
    
    .action2 span {
        font-family: "mont";
        font-size: 12px;
        font-weight: 600;
        display: block;
        width: 80%;
    }
    
    .action2 button {
        background: #FFFFFF;
        border: 1px solid #5D5FEF;
        border-radius: 8px;
        height: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 5px;
    
    }
    
    .action2 img {
        display: block;
    }
    /* .wrapper::-webkit-scrollbar{
        display: none;
    } */
    .notification{
        display: none;
    }
    .photos{
        display: none;
    }
    .photosPhoto{
        width: 100%;
    }
    .reminders {
        display: none;
    }
    .photosComment {
        width: 100%;
    }
    .addContent{
        font-size: 14px;
    }
    .add{
        height: 30px;
    }
    .head{
        font-size: 14px;
        justify-content: space-between;
        padding-right: 15px;
        height: 40px;
    }
    .addComment{
        font-size: 10px;
        width: 75px;
    }
    .photosContainer{
        height: 150px;
        overflow: hidden;
    }
    .photosContainer img{
        height: 100%;
    }
    .reqContent p{
        font-size: 12px;
    }
    .reqContent img{
        height: 30px;
    }
    .texArea{
        height: 30px;
        font-size: 10px;
    }
    .comments {
        display: none;
    }
    

}